import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CertificacionesIngInversa = () => {
  return (
    <Layout location="/certificaciones">
      <SEO title="Certificaciones" />
      <h1>CERTIFICACION ACERO TUBULAR CERRADO</h1>
    </Layout>
  )
}

export default CertificacionesIngInversa
